/** 
 * Everslider - Responsive jQuery Carousel Plugin
*/

.everslider {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 50px 0;
}

.everslider .es-slides {
    position: relative;
    width: 100000px;
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-transform: translate3d(0,0,0);
}

.everslider .es-slides > li {
    position: relative;
    float: left;
    padding: 0!important;
    border: 0!important;
    width: 500px;
    height: 350px;
    margin: 0 10px 0 0;
    cursor: default; 
    cursor: -webkit-grab; 
    cursor: -moz-grab;
     -webkit-transform: translate3d(0,0,0);
}

.everslider.es-swipe-grab .es-slides > li {
    cursor: default; 
    cursor: -webkit-grabbing; 
    cursor: -moz-grabbing; 
}

.everslider .es-slides img {
    width: 100%;
    height: auto;
    max-width: none;
}


/* Preload */

.everslider {
    background: url(/img/everslider/preload.gif) no-repeat center;
}

.everslider.es-slides-ready {
    background: none;
}

.es-slides {
    visibility: hidden;
}

.es-slides-ready .es-slides {
    visibility: visible;
}


/* Navigation */

.es-navigation a {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    padding: 7px;
    background-color: #eee;
}

.es-navigation .es-prev { left: 0 }
.es-navigation .es-next { right: 0 }

.es-navigation .es-first,
.es-navigation .es-last {
    opacity: 0.5;
    cursor: default;
}

.es-navigation a span {
    display: block;
    width: 20px;
    height: 20px;
    text-indent: -9999px;
}

.es-navigation .es-prev span {
    background: url(/img/everslider/arrows.png) no-repeat 0 0;
}

.es-navigation .es-next span {
    background: url(/img/everslider/arrows.png) no-repeat -20px 0;
}

.es-navigation .es-prev span.alt-arrow {
    background: url(/img/everslider/arrows.png) no-repeat 0 -20px;
}

.es-navigation .es-next span.alt-arrow {
    background: url(/img/everslider/arrows.png) no-repeat -20px -20px;
}

/* Pagination */

.es-pagination {
    position: absolute;
    left: 50%;
    bottom: 10px;
}

.es-pagination a {
    position: relative;
    right: 50%;
    float: left;
    width: 13px;
    height: 13px;
    overflow: hidden;
    background: url(/img/everslider/bullets.png) no-repeat 0 0;
    margin: 0 5px 0;
    text-indent: -9999px;
}

.es-pagination .es-active {
    background: url(/img/everslider/bullets.png) no-repeat -13px 0;
}

/* Ticker */

.es-ticker {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
}

.es-ticker a {
    display: block;
    padding: 7px;
    background: #eee;
}

.es-ticker a span {
    display: block;
    width: 20px;
    height: 20px;
    text-indent: -9999px;
}

.es-ticker .es-play span {
    background: url(/img/everslider/ticker.png) no-repeat 0 0;
}

.es-ticker .es-pause span {
    background: url(/img/everslider/ticker.png) no-repeat -20px 0;
}

/* Retina */

@media only screen and (-webkit-min-device-pixel-ratio:1.5) {
    /* Navigation */
    .es-navigation .es-prev span {
        background: url(/img/everslider/arrows@2x.png) no-repeat 0 0;
        background-size: 40px 40px;
    }
    .es-navigation .es-next span {
        background: url(/img/everslider/arrows@2x.png) no-repeat -20px 0;
        background-size: 40px 40px;
    }
    /* Pagination */
    .es-pagination a {
        background: url(/img/everslider/bullets@2x.png) no-repeat 0 0;
        background-size: 26px 13px;
    }
    .es-pagination a.es-active {
        background: url(/img/everslider/bullets@2x.png) no-repeat -13px 0;
        background-size: 26px 13px;
    }
    /* Ticker */
    .es-ticker .es-play span {
        background: url(/img/everslider/ticker@2x.png) no-repeat 0 0;
        background-size: 40px 20px;
    }
    .es-ticker .es-pause span {
        background: url(/img/everslider/ticker@2x.png) no-repeat -20px 0;
        background-size: 40px 20px;
    }
}


// Custom
.image-slider .es-slides > li {
    position: relative;
}


.image-caption {
    position: absolute;
    top: 8%;
    right: 5%;
    height: 35px;
    overflow: hidden;
}

.image-caption span {
    display: block;
    line-height: 15px;
    padding: 10px;
    color: #ddd;
    text-transform: uppercase;
    font-weight: bold;
    background: #222;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    -o-transition: -o-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
}

.image-slider .es-before-slide .image-caption span {
    opacity: 0;
    -webkit-transform: translate(0, -35px);
    -moz-transform: translate(0, -35px);
    -o-transform: translate(0, -35px);
    transform: translate(0, -35px);
    -webkit-transition-duration: 0;
    -moz-transition-duration: 0;
    -o-transition-duration: 0;
    transition-duration: 0;
}

.image-slider .es-after-slide .image-caption span {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

/* featured slider */

.featured-slider {
    padding-top: 20px;
}

.featured-slider .es-pagination {
    left: 0;
    top: 0;
    bottom: auto;
}

.featured-slider .es-pagination a {
    right: auto;
    margin: 0 10px 0 0;
}

.featured-slider .es-slides > li {
    width: 200px;
    margin-right: 5px;
}

.featured-slider .featured-pic {
    position: relative;
    background: #fff;
}

.featured-slider .featured-pic img  {
    opacity: 1;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
}

.featured-slider .featured-pic:hover img {
    opacity: 0.7;
}

.featured-slider .featured-pic:hover > a {
    visibility: visible;
}

.featured-slider .featured-pic > a {
    position: absolute;
    visibility: hidden;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 65px;
    padding: 5px 0;
    margin-top: -15px;
    text-align: center;
    text-decoration: none;
    font-style: italic;
    line-height: 20px;
    color: #fff;
    background: #ff5452;
    z-index: 1;
}

.featured-title {
    padding: 8px 15px 8px;
    color: #666;
    background: #eee;
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ddd;
}

.featured-title > a {
    display: block;
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    color: #444;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 3px;
    text-shadow: 0 1px 0 #fff;
}

.featured-title > a:hover {
    color: #666;
}

.featured-title > span {
    font-size: 11px;
    font-style: italic;
    line-height: 15px;
    color: #666;
}

/* fullwidth slider */

.fullwidth-slider .es-slides > li {
    /* 1.6 ~ image width/height */
    width: 300px;   
    height: 200px;
    background: #111;
    overflow: hidden;
}

.fullwidth-slider .es-navigation a {
    top: 0;
    margin-top: 0;
    background: #ff5452;
}

.fullwidth-slider .es-ticker {
    bottom: auto;
    top: 0;
    margin-right: 34px;
}

.fullwidth-slider .es-slides > li img  {
    opacity: 1;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
}

.fullwidth-slider .es-slides > li:hover img {
    opacity: 0.8;
}

.fullwidth-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: #fff;
    border-bottom: 2px solid #eee;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: #555;
    font-size: 11px;
    line-height: 15px;
}

.fullwidth-title > a {
    display: block;
    color: #999;
    text-transform: uppercase;
    font-style: normal;
    text-decoration: none;
    font-size: 13px;
}

.fullwidth-title > a:hover {
    color: #555;
}

.fullwidth-title span {
    display: none;
    margin-top: 5px;
}

.fullwidth-slider .es-slides > li:hover .fullwidth-title span {
    display: block;
}